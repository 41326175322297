import $ from 'jquery';
import Chart from 'chart.js/auto';

$.fn.dashboardMrrGraph = function () {
	const currency = $(this).data("currency");
	new Chart($(this)[0].getContext('2d'), {
		data: $(this).data('graph'),
		options: {
			responsive: true,
			aspectRatio: 2,
			layout: {
				padding: 0,
			},
			plugins: {
				tooltip: {
					mode: 'index',
					backgroundColor: '#f8fafc',
					bodyColor: '#6E84A3',
					titleColor: '#6E84A3',
					bodyFont: {
						size: 12,
					},
					padding: 12,
					bodySpacing: 6,
					titleMarginBottom: 8,
					titleFont: {
						size: 12,
					},
					boxWidth: 12,
					boxHeight: 12,
					borderColor: '#E3EBF6',
					borderWidth: 1,
					caretPadding: 12,
					footerMarginTop: 20,
					titleAlign: 'center',
				},
				legend: {
					position: "bottom",
					labels: {
						boxWidth: 14,
						boxHeight: 14,
						padding: 16,
						font: {
							size: 14,
						},
						generateLabels: (chart) => {
							const items = Chart.defaults.plugins.legend.labels.generateLabels(chart);

							for (const item of items) {
								item.borderRadius = 3;
								item.lineDash = [0, 0];
								item.lineDashOffset = 0;
								item.lineWidth = 0;
							}

							return items;
						}
					},
				}
			},
			interaction: {
				mode: 'nearest',
				axis: 'x',
				intersect: false
			},
			scales: {
				x: {
					stacked: true,
					title: {
						color: 'rgba(110, 132, 163, 1)',
					},
					grid: {
						display: false,
						drawBorder: false,
						color: "rgba(110, 132, 163, 0.15)",
					},
					ticks: {
						color: 'rgba(110, 132, 163, 1)',
						callback: function(val, index) {
							return this.getLabelForValue(val).substr(0, 3);
						},
					}
				},
				y: {
					stacked: true,
					title: {
						display: true,
						text: `MRR change (${currency})`,
						color: 'rgba(110, 132, 163, 1)',
					},
					grid: {
						drawBorder: false,
						color: "rgba(110, 132, 163, 0.15)",
					},
					ticks: {
						color: 'rgba(110, 132, 163, 1)',
						maxTicksLimit: 9,
					}
				},
				y2: {
					position: "right",
					title: {
						display: true,
						text: `Total MRR (${currency})`,
						color: 'rgba(110, 132, 163, 1)',
					},
					grid: {
						drawBorder: false,
						color: "rgba(110, 132, 163, 0.15)",
					},
					ticks: {
						color: 'rgba(110, 132, 163, 1)',
						maxTicksLimit: 3,
					}
				}
			}
		}
	});
};

$.fn.dashboardInvoicesGraph = function () {
	const currency = $(this).data("currency");
	new Chart($(this)[0].getContext('2d'), {
		data: $(this).data('graph'),
		options: {
			responsive: true,
			aspectRatio: 2,
			layout: {
				padding: 0,
			},
			plugins: {
				tooltip: {
					mode: 'index',
					backgroundColor: '#f8fafc',
					bodyColor: '#6E84A3',
					titleColor: '#6E84A3',
					bodyFont: {
						size: 12,
					},
					padding: 12,
					bodySpacing: 6,
					titleMarginBottom: 8,
					titleFont: {
						size: 12,
					},
					boxWidth: 12,
					boxHeight: 12,
					borderColor: '#E3EBF6',
					borderWidth: 1,
					caretPadding: 12,
					footerMarginTop: 20,
					titleAlign: 'center',
				},
				legend: {
					position: "bottom",
					labels: {
						boxWidth: 14,
						boxHeight: 14,
						padding: 16,
						font: {
							size: 14,
						},
						generateLabels: (chart) => {
							const items = Chart.defaults.plugins.legend.labels.generateLabels(chart);

							for (const item of items) {
								item.borderRadius = 3;
								item.lineDash = [0, 0];
								item.lineDashOffset = 0;
								item.lineWidth = 0;
							}

							return items;
						},
					}
				}
			},
			interaction: {
				mode: 'nearest',
				axis: 'x',
				intersect: false,
			},
			scales: {
				x: {
					stacked: true,
					title: {
						color: 'rgba(110, 132, 163, 1)',
					},
					grid: {
						display: false,
						drawBorder: false,
						color: 'rgba(110, 132, 163, 0.15)',
					},
					ticks: {
						color: 'rgba(110, 132, 163, 1)',
						callback: function(val, index) {
							return this.getLabelForValue(val).substr(0, 3);
						},
					}
				},
				y: {
					stacked: true,
					title: {
						display: true,
						text: `Invoiced net amount (${currency})`,
						color: 'rgba(110, 132, 163, 1)',
					},
					grid: {
						drawBorder: false,
						color: 'rgba(110, 132, 163, 0.15)',
					},
					ticks: {
						color: 'rgba(110, 132, 163, 1)',
					}
				}
			}
		}
	});
};
