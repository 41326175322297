import $ from 'jquery';

// expose jQuery on the window
window.$ = $;
window.jQuery = $;

// import plugins
import select2 from 'select2';
import flatpickr from "flatpickr";


function initPage(container) {
	// init tooltips
	// --------------------
	$(container).tooltip({selector: '[data-toggle="tooltip"]'});

	// init popovers
	// --------------------
	$('[data-toggle="popover"]').popover();

	// init select2
	// --------------------
	$.each(container.find('select[data-select2-init]'), function () {
		var el = $(this);
		var options = {};
		if (el.data('select2-multiple') === 'true') {
			options['multiple'] = true;
		}
		if (el.data('select2-close-on-select') === 'false') {
			options['closeOnSelect'] = false;
		}
		if (el.data('select2-minimum-results-for-search')) {
			options['minimumResultsForSearch'] = el.data('select2-minimum-results-for-search');
		}
		if (el.data('select2-format-result')) {
			options['formatResult'] = window[el.data('select2-format-result')];
		}
		if (el.data('select2-format-selection')) {
			options['formatSelection'] = window[el.data('select2-format-selection')];
		}
		el
		  .addClass('select2-init-select')
			.select2(options)
			.on("select2:opening select2:closing", function (event) {
				// hack to wait for render ...
				setTimeout(function () {
					var search = document.querySelector('.select2-container .select2-dropdown .select2-search .select2-search__field');
					if (search) {
						search.placeholder = "filter options";
					    search.focus();
					}
				}, 0);
			});
	});

	// init flatpickr
	// --------------------
	flatpickr.l10ns.default.firstDayOfWeek = 1;
	container.find('*[data-datetimepicker]').each(function () {
		var el = $(this);
		var format = "Y-m-d H:i:S";
		if (el.data('datetimepicker-format')) {
			format = el.data('datetimepicker-format');
		}
		flatpickr(this, {
			"mode": "single",
			"dateFormat": format,
			"time_24hr": true,
			"defaultHour": 0,
			"enableSeconds": true,
			"enableTime": true,
			"allowInput": true
		})
	});
	container.find('*[data-datepicker]').each(function () {
		var el = $(this);
		var format = 'y-m-d';
		if (el.data('datepicker-format')) {
			format = el.data('datepicker-format');
		}
		flatpickr(this, {
			"mode": "single",
			"dateFormat": format,
			"allowInput": true
		});
	});

	// Init copy url
	container.find('*[data-copy-text]').click(function (event) {
		event.preventDefault();
		event.stopPropagation();
		var elem = event.target;
		parent = $(elem).parent();
		var textArea = document.createElement("textarea");
		textArea.value = $(elem).data('copy-text');
		textArea.style.width = '1px';
		textArea.style.height = '1px';
		parent.append(textArea);
		textArea.focus();
		textArea.select();

		try {
			document.execCommand('copy');
		} catch (err) {
		}

		parent[0].removeChild(textArea);
	});

	// init login form
	// --------------------
	container.find('*[data-login-form] *[data-login-button]').click(function (event) {
		var password = $('*[data-login-form]').find('input[name="auth-password"]');
		if (password.val() === '') {
			// Obtain SSO status of user identified by an e-mail.
			event.preventDefault();
			event.stopPropagation();
			$.post('/organization/is_sso/', {username: $('#id_auth-username').val()}, function (data, status) {
				if (!data.password && data.enforce_password_login) {
					// You do not have valid password. Please consult your organization admin.
					container.find('*[data-no-valid-password]').removeClass('d-none');
					$('*[data-login-methods] div:not(*[data-no-valid-password])').addClass('d-none');
					container.find('*[data-login-form] *[data-login-form-password-container]').addClass('d-none');
				} else if (data.sso === 1 && !data.enforce_password_login) {
					// SSO login
					window.location.replace(data.url)
				} else if (data.sso > 1 && !data.enforce_password_login) {
					// You have multiple SSO, use one of dedicated login pages
					container.find('*[data-multiple-sso-prompt]').removeClass('d-none');
					$('*[data-login-methods] div:not(*[data-multiple-sso-prompt])').addClass('d-none');
					container.find('*[data-login-form] *[data-login-form-password-container]').addClass('d-none');
				} else  {
					// Password login
					$('*[data-login-methods] div').addClass('d-none');
					container.find('*[data-login-form] *[data-login-form-password-container]').removeClass('d-none');
					password.focus();
				}
			});
			return false;
		}
	});

	// disable scroll wheel on number inputs
	// https://solidfoundationwebdev.com/blog/posts/disable-scroll-wheel-on-number-inputs
	// --------------------
	container.find('input[type=number]').on('mousewheel', function(e) {
		$(e.target).blur();
	});

	initSubmitAnimation(container);

	initToggles(container);
}

// Modals
// --------------------

function closeSubmittableModal($modal) {
	$modal.fadeOut(100, function () {
		$(this).remove();
		if ($('.submittable-modal:visible').length === 0) {
			$('body').removeClass('modal-open');
		}
	});
}

function initCloseSubmittableModal(modalContent) {
	$('*[data-dismiss="modal"]', modalContent).on('click', function () {
		closeSubmittableModal(modalContent.closest(".submittable-modal"));
		return false;
	});
	modalContent.find('*[data-submittable-modal-cancel]').on('click', function (e) {
		e.preventDefault();
		closeSubmittableModal(modalContent.closest(".submittable-modal"));
	});
}

function initSubmittableModals() {
	// Submittable modals
	// --------------------
	$(document).on('click', '[data-submittable-modal]', function (e) {
		var component = this;
		var trigger = $(component);
		if (e) {
			e.preventDefault();
		}
		var modalDiv = $('<div class="modal submittable-modal" tabindex="-1" role="dialog" aria-labelledby="submittable-modal" aria-hidden="true"></div>');
		modalDiv.data('submittableModal', component);
		var modalBackdrop = $('<div class="modal-backdrop"></div>');
		var modalContent = $('<div class="modal-content"></div>');
		var modalDialog = $('<div class="modal-dialog"></div>');
		var modalLoader = $('<div class="text-center pt-5 pb-5"><span class="loader loader-xl"></span></div>');
		modalContent.html(modalLoader);
		modalDialog.append(modalContent);
		modalDiv.append(modalBackdrop);
		modalDiv.append(modalDialog);
		$('body').append(modalDiv);

		if (trigger.data('submittable-modal-close-with-backdrop')) {
			modalBackdrop.on('click', function () {
				closeSubmittableModal($(this).closest(".submittable-modal"));
			});
		}

		component.setContent = function (html) {
			var originalContent = $(html).find('#main-content-container');
			var originalTitle = $(html).find('#main-header-title');
			modalContent.html(originalContent.html());
			if (originalTitle && originalTitle.html()) {
				var title = $('<div class="modal-header"></div>');
				var closeButton = $('<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>');
				title.html('<h5 class="modal-title">' + originalTitle.html() + '</h5>');
				title.append(closeButton);
				modalContent.prepend(title);
			}
			initPage(modalContent);
			initCloseSubmittableModal(modalContent);
			initSubmittableModalAttachmentForms(modalContent);
		};

		component.displayErrorMessage = function () {
			var html = '<div class="modal-body text-center">' +
				'<p class="mt-2 mb-4">Sorry, something went wrong.</p>' +
				'<p><a class="btn btn-primary try-again" href="#">Try again</a> &nbsp;&nbsp; <button class="btn btn-outline-secondary" data-dismiss="modal">Close</button></p>' +
				'</div>';
			modalContent.html(html);
			$('.try-again', modalContent).on('click', function (clickEvent) {
				clickEvent.preventDefault();
				closeSubmittableModal(modalContent.closest(".submittable-modal"));
				trigger.click();
			});
			initCloseSubmittableModal(modalContent);
		}

		openSubmittableModal($(this).attr('href'));

		function openSubmittableModal(url) {
			$('body').addClass('modal-open');
			modalBackdrop.addClass('fade show');
			modalDiv.css({
				'display': 'block',
				'opacity': 0,
				'margin-top': -100
			});
			modalDiv.animate({
				'margin-top': 0,
				'opacity': 1
			}).attr('aria-hidden', false);
			modalDiv.focus();

			$.ajax({
				url: url,
				success: function (html) {
					component.setContent(html);
				},
				error: function (data, status, error) {
					component.displayErrorMessage(url);
				}
			});
		}
	});
}

function initSubmittableModalAttachmentForms(modalContent) {
	$('[data-submittable-modal-attachment-form]', modalContent).on('submit', function (event) {
		event.preventDefault();
		var submittableModal = $(event.target).parents('.submittable-modal').data('submittableModal');
		$.ajax({
			url: $(event.target).attr('action'),
			type: 'POST',
			data : $(event.target).serialize(),
			xhr: function() {
				var xhr = new XMLHttpRequest();
				xhr.onreadystatechange = function() {
					if (xhr.readyState == 2) {
						if (xhr.getResponseHeader('Content-Disposition')) {
							xhr.responseType = "blob";
						} else {
							xhr.responseType = "text";
						}
					}
				};
				return xhr;
			},
			success: function(data, textStatus, jqXHR){
				if (jqXHR.getResponseHeader('Content-Disposition')) {
					var a = document.createElement('a');
					var url = window.URL.createObjectURL(data);
					var cancelLink = $('[data-submittable-modal-cancel]', event.target);
					a.href = url;
					a.download = 'attachment-file';
					a.style.display = "none";

					var disposition = jqXHR.getResponseHeader('Content-Disposition');
					if (disposition && disposition.indexOf('attachment') !== -1) {
						var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
						var matches = filenameRegex.exec(disposition);
						if (matches != null && matches[1]) {
						  a.download = matches[1].replace(/['"]/g, '');
						}
					}

					document.body.append(a);
					a.click();
					window.URL.revokeObjectURL(url);
					cancelLink.click();
				} else {
					submittableModal.setContent(jqXHR.responseText);
				}
			},
			error: function () {
				submittableModal.displayErrorMessage();
			}
		});
		return false;
	});
}

function enableSubmitAnimation(buttons) {
	buttons.each(function () {
		let $button = $(this)
		.data("submit-animation-original-content", $(this).html())
		.html('<span class="loader" role="status" aria-hidden="true"></span>');
		setTimeout(function () {
			$button.attr("disabled", "disabled");
		}, 0);
	});
}
function disableSubmitAnimation(buttons) {
	buttons.each(function () {
		$(this).removeAttr("disabled")
		.html($(this).data("submit-animation-original-content"));
	});
}

function initSubmitAnimation(container) {
	$('[data-submit-animation]', container).each(function () {
		var $button = $(this);
		$button.closest("form").on("submit", function () {
			enableSubmitAnimation($button);
		});
	});
}

window.initSubmitAnimation = initSubmitAnimation;
window.enableSubmitAnimation = enableSubmitAnimation;
window.disableSubmitAnimation = disableSubmitAnimation;
window.initSubmittableModals = initSubmittableModals;
window.closeSubmittableModal = closeSubmittableModal;

window.addMessage = function (messageType, messageText) {
	var messageClass = '';
	switch (messageType) {
		case 'success':
			messageClass = 'alert-success';
			break;
		case 'error':
			messageClass = 'alert-danger';
	}
	$(".main-messages").append(
		'<div class="alert ' + messageClass + ' alert-dismissible">' +
		'<div class="alert-text">' + messageText + '</div>' +
		'<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
		'<span aria-hidden="true">×</span>' +
		'</button>' +
		'</div>' +
		'</div>'
	);
};

window.formatDecimal = function (number, options) {
	number = new Number(number);
	options = options || {};
	return number.toLocaleString(options.locale || "da", {
		style: "decimal",
		minimumFractionDigits: options.minimumFractionDigits || 2,
		maximumFractionDigits: options.maximumFractionDigits || 2,
	})
}

// init plugins after body load
$(function () {
	var container = $('body');

	initPage(container);
	initSubmittableModals();
});
