import $ from 'jquery';

$.fn.fenerumAutocomplete = function () {
	var $autocomplete = this;
	var labelFunction = Function($autocomplete.data('label'))();
	function getURL(id) {
		let filters = {};
		if (id) {
			filters[$autocomplete.data("id-field")] = id;
		}
		if ($.isEmptyObject(filters)) {
			return $autocomplete.data("autocomplete-url");
		}
		return $autocomplete.data("autocomplete-url") + "?" + $.param(filters);
	}
	$autocomplete
		.select2({
			ajax: {
				url: getURL(),
				headers: $autocomplete.data("autocomplete-headers") || {},
				dataType: "json",
				data: function (params) {
					return {
						search: params.term,
						page: params.page || 1,
						...($autocomplete.data("autocomplete-filters") || {}),
					};
				},
				processResults: function (data) {
					return {
						results: data.results.map(function (item) {
							return {
								id: item[$autocomplete.data("id-field")],
								text: labelFunction(item),
								data: item
							}
						}),
						pagination: {
							more: data.next !== null
						}
					};
				}
			}
		})
		.on("select2:select", function (event) {
			$autocomplete.data("selected-item-data", event.params.data.data);
			$autocomplete.trigger("autocomplete:selected");
		})
		.on("select2:opening select2:closing", function (event) {
			// hack to wait for render ...
			setTimeout(function () {
		    var search = document.querySelector('.select2-container .select2-dropdown .select2-search .select2-search__field');
				if (search) {
					search.focus();
					search.placeholder = "filter options";
				}
			}, 0);
		});
	if ($autocomplete.data("initial-value")) {
		$.ajax({
			type: 'GET',
			url: getURL($autocomplete.data("initial-value")),
			headers: $autocomplete.data("autocomplete-headers") || {},
		}).then(function (data) {
			var itemData = data["results"][0];
			var option = new Option(labelFunction(itemData), itemData[$autocomplete.data("id-field")]);
			$autocomplete.append(option).val(option.value).trigger('change');
			$autocomplete.trigger({
				type: 'select2:select',
				params: {
					data: {data: itemData}
				}
			});
		});
	}
};
