window.initToggles = function (container) {
	$("[data-widget-type='toggleable-widget']", $(container)).each(function (index, el) {
		let truthFieldName;
		if ($(el).parents('.formset-inline').length) {
			// we are in a formset
			let baseTruthFieldName = el.dataset.widgetSourceName;
			let splitted = el.name.split('-');
			let prefix = `${splitted[0]}-${splitted[1]}-`;
			truthFieldName = `${prefix}${baseTruthFieldName}`;
		} else {
			truthFieldName = el.dataset.widgetSourceName;
		}
		let triggerType = el.dataset.widgetTriggerType;
		if (triggerType === 'checkbox') {
			let truthField = $(`input[name=${truthFieldName}]`);
			let showOnChecked = (el.dataset.widgetTriggerValue === "True");
			if (truthField.is(":checked") !== showOnChecked) {
				$(el).parent().hide()
			}
			$(truthField).click(() => {
				$(el).parent().toggle()
			})
		}
		if (triggerType === 'select') {
			let truthField = $(`select[name=${truthFieldName}]`);
			let trigger = el.dataset.widgetTriggerValue;
			if (truthField.val() !== trigger || trigger === "any-non-null") {
				$(el).closest('.form-group').hide()
			}
			$(truthField).change(() => {
				if (truthField.val() !== trigger && trigger !== "any-non-null") {
					$(el).closest('.form-group').hide()
				} else if (trigger === "any-non-null" && !truthField.val()) {
					$(el).closest('.form-group').hide()
				} else {
					$(el).closest('.form-group').show(400)
				}
			})
		}
	});
}
